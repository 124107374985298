//Packages
import React, { useEffect, useState } from 'react'
import { confirmResetPassword, resendSignUpCode } from 'aws-amplify/auth'
import { Form, Result, Modal } from 'antd'
import { navigate } from 'gatsby'
import { toast, ToastContainer } from 'react-toastify'
import { ApolloError, useMutation } from '@apollo/client'

//Components
import ResetPasswordComponent from '../../components/ResetPassword'

//Configuracion
import '../../../config/amplify'

//Types
import AuthTypes from '../../types/AuthTypes'

//Utils
import passwordValidator from '../../utils/passwordValidator'
import useErrorHandler from '../../hooks/useErrorHandler'

//Assets
import sendMail from '../../data/assets/images/sendMail.png'
import { StyledButtonPrimary } from '../../components/Shared/Button/styledComponent'
import ModalResult from '../../components/Shared/ModalResult'
import { getParameter } from '../../utils/queryString'

// Querys
import { UPDATE_FIRST_LOGIN } from '../../graphql/mutation/partner'

const ResetPasswordContainer: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [numberAttemps, setNumberAttemps] = useState(0)
  const [result, setResult] = useState({
    isModalVisible: false,
    content: <></>
  })
  const [typeError, setTypeError] = useState('')
  const [apolloError, setApolloError] = useState<ApolloError>()
  const [formResetPassword] = Form.useForm<AuthTypes.ResetPassword>()
  const errorMessage = useErrorHandler(apolloError, loading)

  const [updateFisrtLoginUser] = useMutation(UPDATE_FIRST_LOGIN)

  const handleUpdateFirstLogin = (phoneNumber: string) => {
    updateFisrtLoginUser({
      variables: {
        phoneNumber: phoneNumber
      }
    })
  }

  const handleResetPassword = async (data: AuthTypes.ResetPassword) => {
    setTypeError('reset')
    setLoading(true)
    if (
      passwordValidator(data.password) &&
      passwordValidator(data.repeatPassword)
    ) {
      if (data.password === data.repeatPassword) {
        setNumberAttemps(numberAttemps + 1)
        confirmResetPassword({
          username: `+52${data.userName}`,
          confirmationCode: data.code,
          newPassword: data.password
        })
          .then(() => {
            setResult({
              isModalVisible: true,
              content: (
                <Result
                  key={`success-${Math.random()}`}
                  status={'success'}
                  subTitle={
                    getParameter('code') === 'sms'
                      ? `Contraseña generada con exito.`
                      : `Tu cuenta ha sido confirmada, ingresa a tu cuenta.`
                  }
                  extra={[
                    <StyledButtonPrimary
                      type="button"
                      onClick={() => {
                        navigate('/auth/login')
                        // getParameter('code') !== 'sms' &&
                        handleUpdateFirstLogin(data.userName)
                      }}
                    >
                      Aceptar
                    </StyledButtonPrimary>
                  ]}
                />
              )
            })
          })
          .catch((error) => {
            setLoading(false)
            setApolloError(error)
          })
      } else {
        setLoading(false)
        toast.error('Las contraseñas no coinciden. Intenta de nuevo')
      }
    } else {
      setLoading(false)
      if (data.password.includes(' ') || data.repeatPassword.includes(' ')) {
        toast.error(
          'Tu contraseña no debe incluir espacios en blanco, intenta nuevamente.'
        )
      } else {
        toast.error(
          'La contraseña no cumple con las carácteristicas, intenta nuevamente.'
        )
      }
    }
  }

  const handleResendCode = async () => {
    setTypeError('resend')
    await resendSignUpCode({
      username: '+52' + formResetPassword.getFieldValue('userName')
    })
      .then(() => {
        setResult({
          isModalVisible: true,
          content: (
            <Result
              key={`success-${Math.random()}`}
              icon={<img src={sendMail} alt="sendmail" />}
              status={'success'}
              subTitle={`Se ha enviado código de verificación vía SMS`}
              extra={[
                <StyledButtonPrimary
                  type="button"
                  onClick={() =>
                    setResult({ content: <></>, isModalVisible: false })
                  }
                >
                  Aceptar
                </StyledButtonPrimary>
              ]}
            />
          )
        })
      })
      .catch((err) => {
        setLoading(false)
        setApolloError(err)
      })
  }

  useEffect(() => {
    if (errorMessage && apolloError) {
      if (typeError === 'resend') {
        setResult({
          isModalVisible: true,
          content: (
            <ModalResult
              key={`success-${Math.random()}`}
              status={'error'}
              subTitle={errorMessage}
              extra={
                <StyledButtonPrimary
                  type="button"
                  onClick={() => {
                    setResult({ content: <></>, isModalVisible: false })
                    navigate(`/auth/help`)
                  }}
                >
                  Aceptar
                </StyledButtonPrimary>
              }
            />
          )
        })
      } else {
        setResult({
          isModalVisible: true,
          content: (
            <Result
              key={`success-${Math.random()}`}
              status={'error'}
              subTitle={errorMessage}
              extra={[
                <StyledButtonPrimary
                  type="button"
                  onClick={() => {
                    setResult({ content: <></>, isModalVisible: false })
                    if (
                      numberAttemps === 2 ||
                      apolloError.message ===
                        'Attempt limit exceeded, please try after some time.'
                    ) {
                      navigate(`/auth/help`)
                    }
                  }}
                >
                  Aceptar
                </StyledButtonPrimary>
              ]}
            />
          )
        })
      }
      setApolloError(undefined)
      setTypeError('')
    }
  }, [errorMessage, apolloError])

  return (
    <>
      <ResetPasswordComponent
        handleResendCode={handleResendCode}
        handleResetPassword={handleResetPassword}
        form={formResetPassword}
        loading={loading}
      />
      <Modal
        visible={result.isModalVisible}
        footer={null}
        onCancel={undefined}
        onOk={undefined}
      >
        {result.content}
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export default ResetPasswordContainer
