import { Checkbox } from 'antd'
import styled from 'styled-components'

interface Login {
  img: string
}

export const StyledAuthContainer = styled.div<Login>`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`
export const Img = styled.img`
  width: 100%;
`
export const CheckBoxPrimary = styled(Checkbox)`
  margin: 2px;
  font-size: 12px;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0b4793;
    border: none;
    color: white;
  }

  .ant-checkbox + span {
    font-size: 12px;
  }
`
