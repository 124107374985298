/* istanbul ignore file */
// Packages
import React, { useState, useEffect } from 'react'
import { Row, Input, Col, Form } from 'antd'
import loadable from '@loadable/component'

// Styled Components
import { CheckBoxPrimary } from './styledComponents'

// Utils
import { getParameter } from '../../utils/queryString'
import {
  StyledButtonPrimary,
  StyledButtonText
} from '../Shared/Button/styledComponent'

type props = {
  handleResetPassword: any
  handleResendCode: any
  form: any
  loading: boolean
}

const LazyFormContent = loadable(() => import('../FormContent'))

const ResetPasswordComponent: React.FC<props> = ({
  handleResetPassword,
  handleResendCode,
  form,
  loading
}: any) => {
  const userName = getParameter('userName')
  const [validatePassword, setValidatePassword] = useState({
    min: false,
    max: false,
    uppercase: false,
    lowercase: false,
    digits: false,
    caracter: false
  })

  useEffect(() => {
    form.setFieldsValue({
      userName: userName
    })
  }, [])

  const onChangePassword = (value: string) => {
    setValidatePassword({
      min: value.length >= 8,
      max: value.length <= 100,
      uppercase: new RegExp('[A-Z]').test(value),
      lowercase: new RegExp('[a-z]').test(value),
      digits: new RegExp('[0-9]').test(value),
      caracter: new RegExp('[!-/:-?[-_¿¡¨´{}]').test(value)
    })
  }

  const content = () => {
    return (
      <>
        <Row style={{ width: '100%' }} hidden>
          <Col span={24}>
            <Form.Item
              name="userName"
              label="Teléfono"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input placeholder="Teléfono" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              name="code"
              label="Código de verificación"
              rules={[
                {
                  message: 'Ingresa un código válido',
                  pattern: RegExp('([0-9]){6}'),
                  required: true
                }
              ]}
            >
              <Input
                placeholder="Código de verificación 6 dígitos"
                maxLength={6}
                inputMode="numeric"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              name="password"
              label="Nueva Contraseña"
              rules={[{ required: true, message: 'Ingresa tu contraseña' }]}
            >
              <Input.Password
                placeholder="Contraseña Nueva"
                data-testid="onChangePassword"
                onChange={(e) => onChangePassword(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              name="repeatPassword"
              label="Confirmar Contraseña"
              rules={[{ required: true, message: 'Repite la contraseña' }]}
            >
              <Input.Password
                placeholder="Confirmar Contraseña"
                disabled={
                  Object.values(validatePassword).filter((x) => x === false)
                    .length > 0
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <h3>La contraseña debe contener…</h3>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Row style={{ width: '100%' }}>
              <CheckBoxPrimary
                value={validatePassword.min}
                checked={validatePassword.min}
              >
                Mínimo 8 caracteres
              </CheckBoxPrimary>
            </Row>
            <Row style={{ width: '100%' }}>
              <CheckBoxPrimary
                value={validatePassword.digits}
                checked={validatePassword.digits}
              >
                Mínimo 1 número
              </CheckBoxPrimary>
            </Row>
            <Row style={{ width: '100%' }}>
              <CheckBoxPrimary
                value={validatePassword.caracter}
                checked={validatePassword.caracter}
              >
                Mínimo 1 caracter especial # & % _ .
              </CheckBoxPrimary>
            </Row>
            <Row style={{ width: '100%' }}>
              <CheckBoxPrimary
                value={validatePassword.uppercase}
                checked={validatePassword.uppercase}
              >
                Mínimo 1 mayúscula
              </CheckBoxPrimary>
            </Row>
            <Row style={{ width: '100%' }}>
              <CheckBoxPrimary
                value={validatePassword.lowercase}
                checked={validatePassword.lowercase}
              >
                Mínimo 1 minúscula
              </CheckBoxPrimary>
            </Row>
          </Col>
        </Row>
        <br />
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item>
              <StyledButtonPrimary
                type="submit"
                disabled={
                  Object.values(validatePassword).filter((x) => x === false)
                    .length > 0
                }
              >
                ACEPTAR
              </StyledButtonPrimary>
              <StyledButtonText
                type="button"
                onClick={() => handleResendCode()}
              >
                REENVIAR CÓDIGO
              </StyledButtonText>
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <LazyFormContent
        content={content()}
        titleForm={'Crear contraseña'}
        subtitleForm={
          'Ingresa el código de verificación y la contraseña nueva, cumpliendo los requerimientos mínimos'
        }
        form={form}
        loading={loading}
        onFinish={handleResetPassword}
      />
    </>
  )
}

export default ResetPasswordComponent
