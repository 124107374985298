//Packages
import React from 'react'
import { navigate } from 'gatsby'

//Layouts
import { MainLayout } from '../../../layout/MainLayout'

//Containers
import ResetPasswordContainer from '../../../containers/ResetPasswordContainer'

import { isBrowser, isLoggedIn } from '../../../utils/storage'

const ResetPasswordPage: React.FC = () => {
  return (
    <MainLayout>
      {!isLoggedIn() && <ResetPasswordContainer />}
      {isLoggedIn() && isBrowser() && navigate('/')}
    </MainLayout>
  )
}

export default ResetPasswordPage
